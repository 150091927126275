import React, { useEffect, useState } from "react";
import css from "./index.module.scss";
import Link from "next/link";
import x from "/scripts/apis/x/index.js";
import XSvg from "public/images/logos/x/xhorizontal.svg";
import gsap from "scripts/gsap/index.js";
import MorphSVGPlugin from "scripts/gsap/MorphSVGPlugin";
import BurgerSvg from "/public/images/icons/burger.svg";
import {useRouter} from "next/router";
import { useUser } from "scripts/apis/x/useUser";


export default function Header({minimal = false}) {
  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState("/");
  const user = useUser({refreshInterval: 10000});
  const router = useRouter();

  function handleSignOut() {
    x.query(x.endpoints.SELF_SIGN_OUT, {}).then(() => {
      user.mutate(null);
      router.push("/auth/signin");
    });
  }
  useEffect(() => {
    gsap.registerPlugin(MorphSVGPlugin);
    setUrl(window.location.href);
    document.body.classList.remove("noscroll");
  }, []);

  let links = [
    {
      row: "primary",
      url: "/",
      label: "Enter the Hunt",
      visible: () => true,
      active: () => router.pathname === "/",
    },
    {
      row: "primary",
      url: "/prizes",
      label: "Prizes",
      visible: () => true,
      active: () => router.pathname === "/prizes",
    },
    {
      row: "primary",
      url: "/profile",
      label: "Puzzles",
      visible: () => !user.loading && user.value.id,
      active: () => router.pathname === "/profile",
    },
    {
      row: "primary",
      url: `/auth/register?ref=${url}`,
      label: "Register",
      visible: () => !user.loading && !user.value.id,
      active: () => router.pathname === "/auth/register",
    },
    {
      row: "secondary",
      url: "/rules",
      label: "Rules",
      visible: () => true,
      active: () => router.pathname === "/rules",
    },
    {
      row: "secondary",
      url: "/contact",
      label: "Contact",
      visible: () => true,
      active: () => router.pathname === "/contact",
    },
    {
      row: "secondary",
      url: `/auth/signin?ref=${url}`,
      label: "Sign in",
      visible: () => !user.loading && !user.value.id,
      active: () => router.pathname === "/auth/signin",
    },
    {
      row: "secondary",
      url: `/auth/signin?ref=${url}`,
      label: "Sign out",
      onClick: handleSignOut,
      visible: () => !user.loading && user.value.id,
      active: () => false,
    },
  ];

  let headerClassName = css.header;
  if (isOpen){
    headerClassName += ` ${css.open}`;
  }

  return (
    <header className={headerClassName}>
      <div className={`sectionInner ${css.sectionInner}`}>
        {!minimal && <Link href="/"><a aria-label="X Marks the Site" className={css.logo}><XSvg/></a></Link>}
        {minimal && <div className={css.logo}><XSvg/></div>}
        {!minimal &&
        <nav className={css.menu}>
          <div className={css.links}>
            <div className={css.linksRow}>
              {links.filter((link) => link.row === "primary" && link.visible())
                .map((link) => <Link key={link.label} href={link.url}><a className={`${css.navItem} ${link.active() ? css.active : ""}`}>{link.label}</a></Link>)
              }
            </div>
            <div className={css.linksRow}>
              {links.filter((link) => link.row === "secondary" && link.visible())
                .map((link) => <Link key={link.label} href={link.url}><a onClick={link.onClick || null} className={`${css.navItem} ${css.secondary} ${link.active() ? css.active : ""}`}>{link.label}</a></Link>)
              }
            </div>
          </div>
          <button className={css.burger} onClick={() => {
            if (isOpen === false) {
              setIsOpen(null);
              gsap.fromTo(`.${css.header} .${css.navItem}`, {
                translateX: "100%",
              }, {
                translateX: 0,
                ease: "sine.out",
                duration: 0.3,
                stagger: {
                  amount: 0.2,
                },
              });

              //morph the burger button
              gsap.fromTo(".icon.burger .burgerOpen", { morphSVG: ".icon.burger .burgerOpen" }, { morphSVG: ".icon.burger .burgerClosed", duration: 0.5, ease: "sine.out" });
              document.body.classList.add("noscroll");

              //allow user to close the menu
              setTimeout(() => {
                setIsOpen(true);
              }, 550); //total time of the nav sliding animation
            } else if (isOpen === true){
              setIsOpen(null);
              let tl = gsap.fromTo(`.${css.header} .${css.navItem}`, {
                translateX: "0%",
              }, {
                translateX: "100%",
                ease: "sine.in",
                duration: 0.3,
                stagger: {
                  amount: 0.2,
                  from: "end",
                },
                clearProps: "transform",
              });

              //morph the burger button
              gsap.fromTo(".icon.burger .burgerOpen", { morphSVG: ".icon.burger .burgerClosed" }, { morphSVG: ".icon.burger .burgerOpen", duration: 0.5, ease: "sine.in" });

              document.body.classList.remove("noscroll");
              //allow user to open the menu again
              setTimeout(() => {
                setIsOpen(false);
              }, 550); //total time of the nav sliding animation
            }
          }} aria-label="Hamburger menu" ><BurgerSvg /></button>

        </nav>}
      </div>
    </header>
  );
}
