import React from "react";
import css from "./index.module.scss";
import Link from "next/link";
import XSvg from "public/images/logos/x/full.svg";
import FacebookSvg from "public/images/icons/facebook.svg";
import TwitterSvg from "public/images/icons/twitter.svg";
import InstagramSvg from "public/images/icons/instagram.svg";


export default function Footer({ auth, minimal = false }) {
  return (
    <footer className={`${css.footer} ${minimal ? css.minimal : ""}`}>
      <div className={`sectionInner ${css.sectionInner}`}>
        <div className={css.top}>
          <div className={css.logo}><XSvg /></div>

          {!minimal && <div className={css.social}>
            <div className={css.label}>Follow</div>
            <div className={css.socialLinks}>
              <a aria-label="Facebook Profile" href="https://www.facebook.com/abadthink" className={css.socialLink}><FacebookSvg/></a>
              <a aria-label="Twitter Profile" href="https://twitter.com/abadthink" className={css.socialLink}><TwitterSvg/></a>
              <a aria-label="Instagram Profile" href="https://www.instagram.com/abadthink" className={css.socialLink}><InstagramSvg/></a>
            </div>
          </div>}

          {!minimal && <div className={css.links}>
            <div className={css.label}>Links</div>
            {/*
            <Link href="/">Challenge</Link>
            <Link href="/rules">Rules</Link>
            <Link href="/contact">Contact</Link>
            */}
            <Link href="/terms/terms-and-conditions">Terms of Service</Link>
            <Link href="/terms/privacy">Privacy Policy</Link>
            <Link href="/terms/raffle">Raffle Terms</Link>
          </div>}
        </div>
        <div className={css.bottom}>
          {!minimal && <div className={css.copyright}>Copyright © 2022 <a className="custom" href="https://www.abadthink.com/">A Bad Think</a> and created by <a className="custom" href="https://deviatelabs.com">Deviate Labs</a></div>}
          {minimal && <div className={css.copyright}>Copyright © 2022 A Bad Think and created by Deviate Labs</div>}

          {!minimal && <div className={css.copyright}>Infinite art created by <a className="custom" href="https://www.instagram.com/knotpaolo/">@knotpaolo</a></div>}
          {minimal && <div className={css.copyright}>Infinite art created by @knotpaolo.</div>}

          {!minimal && <div className={css.copyright}><a className="custom" href="mailto:hello@xmarksthe.site">hello@xmarksthe.site</a></div>}
          {minimal && <div className={css.copyright}><a className="custom" href="mailto:hello@xmarksthe.site">hello@xmarksthe.site</a></div>}


        </div>
      </div>
    </footer>
  );
}
