const endpoints = {
  SONG_DOWNLOAD: "/api/songs/download",
  SELF_SIGN_OUT: "/api/auth/sign-out",
  CREATE_ALBUM: "/api/forms/create-new-album-sdhf2",
  SAVE_ALBUM_ART: "/api/forms/save-album-art",
  CREATE_TRACK: "/api/forms/create-new-track-sdhf2",
  SUBMIT_CODE: "/api/forms/submit-code",


  AUTH_ME: "/api/auth/local/me",
  AUTH_SIGN_OUT: "/api/auth/local/sign-out",
  AUTH_REGISTER: "/api/auth/local/register",
  AUTH_SIGN_IN: "/api/auth/local/signin",
  AUTH_FORGOT: "/api/auth/local/forgot",
  AUTH_RESET: "/api/auth/local/reset",

};

const errors = {
  "P2002": (err) => `Error: Invalid duplicate fields: ${err.meta.target.join(",")}`,
  "IAA1": (err) => "No uses remaining.",
};


function query(endpoint, data){
  return fetch(endpoint, {
    method: "POST",
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    });
}

const ex = {
  endpoints,
  query,
};
export default ex;
