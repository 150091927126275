import useSWR from "swr";
import x from "/scripts/apis/x/index.js";
import { getCookie } from "scripts/utils";

export function useUser(swrOpts) {
  const fetcher = (args) => {
    if (getCookie("jwt")){
      return new Promise(async(resolve, reject) => {
        let res = await x.query(args, {});
        if (!res.error){
          resolve(res);
        } else {
          if (res.error !== 403){
            console.error(res);
          }
          resolve({});
        }
      });
    }
    return {};
  };
  const key = x.endpoints.AUTH_ME;
  const { data, error, mutate } = useSWR(key, fetcher, swrOpts);

  return {
    value: data, //data object
    loading: !error && !data, //isLoading
    error, //error value
    mutate,
  };
}

